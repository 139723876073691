<script setup lang="ts">
import { BlindWatermark } from 'watermark-js-plus'

const decodeImage1 = ref('')
const decodeImage2 = ref('')

function handleFile(event: { target: HTMLInputElement }) {
  const [file] = event.target.files!
  if (file) {
    BlindWatermark.decode({
      fillColor: '#ffffff',
      compositeTimes: 3,
      compositeOperation: 'overlay',
      url: URL.createObjectURL(file),
      onSuccess: (imageBase64: string) => { decodeImage1.value = imageBase64 },
    })

    BlindWatermark.decode({
      fillColor: '#ffffff',
      compositeTimes: 3,
      compositeOperation: 'color-dodge',
      url: URL.createObjectURL(file),
      onSuccess: (imageBase64: string) => { decodeImage2.value = imageBase64 },
    })
  }
}
</script>

<template>
  <Head>
    <Meta name="robots" content="noindex, nofollow" />
  </Head>
  <ClientOnly>
    <section class="view-content">
      <input ld-button="~ green lg" accept="image/*" type="file" @change="handleFile">
      <div class="w-full flex flex-col">
        <img class="w-full flex-1" :src="decodeImage1">
        <img class="w-full flex-1" :src="decodeImage2">
      </div>
    </section>
  </ClientOnly>
</template>
